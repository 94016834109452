.programcard{
 display: grid;
 grid-template-columns: auto auto auto;
 justify-content: space-evenly;
 margin: 10px 0px;
/* background-image: url(https://i.pinimg.com/564x/ca/8e/8a/ca8e8a9292ede8de0401bd1837a7b30c.jpg); */
background-color: rgb(178, 247, 161);
position: relative;

overflow: hidden;

}
@media only screen and (max-width: 950px) {
    .programcard {
        display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
  
    }
}
@media only screen and (max-width: 750px) {
    .programcard {
        display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
  
    }
}


.programcard::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(https://i.pinimg.com/564x/ca/8e/8a/ca8e8a9292ede8de0401bd1837a7b30c.jpg); 
    opacity: 0.8;
    /* Adjust the opacity value (from 0 to 1) */
}