.overflow-hidn{
 
    
    /* height: 70vh; */
}
.Gallery-container{
    overflow: hidden;
    width: 90vw;
    height: 100vh;
    margin-left: auto;
    margin-right: auto;


    
}
.img{
    /* width: 50px; */
   
        max-width: 100%;
            /* Ensure the image doesn't exceed the container width */
     
            /* Ensure the image doesn't exceed the container height */
            width: auto;
            /* Allow the image to adjust its width based on the height */
            height: auto;
            /* Allow the image to adjust its height based on the width */
            object-fit: cover;
            /* Maintain aspect ratio and fit the entire image within the container */
            display: block;
            /* Remove any residual spacing */
            margin: 0 auto;
            border-radius: 20px;
           

}

.gallery-map{
    width: 1400px;
    margin: 20px auto;
    columns: 4;
    column-gap: 40px;
    /* display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-template-rows: masonry; */
}
.gallery-map .box{
    width: 100%;
    margin-bottom: 10px;
    break-inside: avoid;
}
.gallery-map .box img{
    max-width: 100%;
    border-radius: 15px;

}

@media (max-width: 1200px) {
    .gallery-map {
      width: calc(100% - 40%);
      columns: 3;
    }
  }
@media (max-width: 768px) {
    .gallery-map {
      
      columns: 2;
    }
  }
    .Gallerycard{
   display: grid;
   grid-template-columns: auto auto auto auto ;
   justify-content: space-between;
   width: 95%;
   row-gap: 20px;
    column-gap: 6px;
   margin-left: auto;
   margin-right: auto;
   margin-top: 15px;
   /* border: 2px solid red; */
   overflow: hidden;
}
@media only screen and (max-width: 1430px) {
    .Gallerycard {
        grid-template-columns: auto auto auto ;
        justify-content: space-around;
    }
}
@media only screen and (max-width: 1060px) {
    .Gallerycard {
        grid-template-columns: auto auto  ;
        justify-content: space-around;
    }
}
@media only screen and (max-width: 680px) {
    .Gallerycard {
        grid-template-columns: auto   ;
        justify-items: center;
    }
}
.galleryCard{
    height: 300px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    margin: 0px 14px;    
}
.Gallery-card:hover{
    /* background-color: blue; */
    border: 2px solid black;
}

.ant-carousel{
    display: flex;
    justify-content: center;
}