


.card{
    display: flex;
    justify-content: center;
    /* border: 2px solid red; */
    flex-direction: column;
    border-radius: 15px;
    /* font-family: 'Anuphan', sans-serif; */
    font-family: 'Anuphan', sans-serif;
  
}
.cardButton{
    border: none;
    background-color: #fff;
}
.bttn{
    border-radius: 20px;
    background-color: transparent;
    color: #fff;
    /* justify-self: center; */
    align-self: center;
    border: 1px solid rgb(96, 96, 96);
    
}
.bttn:hover{
    /* background-color: #d0cece; */
    background-color: #ed4c2c;
    color: #fff;
}