.parent{
    background-color: #56509f;
    display: flex;
    justify-content: start;
    flex-direction: column;
    margin-top: 20px;
    border-radius:40px ;
    padding: 20px 5px;
    padding-bottom: 50px;
    margin: 0px 11px ;
   
    
}
h2{
    font-size: 42px;
    /* margin-top: 10px ; */
    margin-left: 40px;
    padding: 10px;
    color: #fff;
}
.comments>p{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 35rem;
    border: 2px solid rgb(255, 255, 255);
    margin: 5px;
    padding: 15px 20px;
    /* height: 8rem; */
   background-color: aliceblue;
   border-radius: 35px;
   font-weight: 500;
}
.comments{
    display: flex;
    justify-content: space-evenly;
    /* margin: 10px; */
   align-content: center;
    /* border: 2px solid red; */
    padding: 10PX 20PX;
}

.comments>p>span{
    display: flex;
    justify-content: flex-end;
}