
.footer-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 40px 5px;
    margin-top: 10px  ;
    border-radius: 35px;
    background-color: #e26262;
    width: 100%;
    color: #fff !important;
}

.footer-row{
    color: #fff !important;
    font-weight: 500;
    padding: 5px 4px;
    text-align: center;
}

.footer-Heading{
    font-size: 20px;
    margin-bottom: 2px;
}
.footer-column:hover{
    color: #d6d6d6;
    text-decoration: underline;
    cursor: pointer;
}


Link{
    color: #fff !important;
    font:50px
}

.footer-container-div{
    margin: 0px 11px ;
}